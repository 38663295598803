<template>
  <div>
    <!-- 面包屑导航 -->
    <Crumbs></Crumbs>
    <!-- 内容主体 -->
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <!-- 搜索框 -->
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <span
              >公司名称：
              <el-input
                placeholder="请输入公司名称"
                clearable
                v-model="inputCompanyName"
                class="ipt_width project_width"
                @click="searchByCompanyName"
              >
              </el-input
            ></span>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <!-- 添加按钮 -->
        <div class="fr">
          <el-button type="warning" icon="el-icon-plus" @click="addOutsource()">新增</el-button>
        </div>
      </div>
      <!-- 表格区域 -->
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="outsourceListRef"
            row-key="id"
            border
            :data="outsourceList"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" width="50" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="name"
              sortable
              min-width="220"
              label="公司名称"
            ></el-table-column>
            <el-table-column align="center" prop="legalPerson" label="法人"></el-table-column>
            <el-table-column align="center" prop="address" label="地址"></el-table-column>
            <el-table-column align="center" prop="phone" label="电话"></el-table-column>
            <el-table-column align="center" prop="mail" label="邮箱"></el-table-column>
            <el-table-column prop="title" align="center" fixed="right" label="操作" width="160">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  class="text_Details_Bgc"
                  size="small"
                  @click.stop="gotoDeatil(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Edit_Bgc"
                  @click.stop="gotoEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  class="text_Remove_Bgc"
                  @click.stop="deleteOutsource(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页栏 -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit :isShow.sync="showEditDialog" :options="form"></Edit>
  </div>
</template>

<script>
// 引入vuex
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('./Edit.vue'),
  },
  data() {
    return {
      outsourceList: [], //外协列表数据
      loading: false,
      // 分页参数
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      inputCompanyName: '', //输入公司名称
      form: {},
      showEditDialog: false, //控制添加组件显示
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  provide() {
    return {
      re: this.re,
    }
  },
  watch: {
    outsourceList: {
      // immediate:true,//初始化立即执行
      deep: true,
      handler: function (newVal, oldVal) {
        let num = newVal.length
        if (num === 0 && this.parameter.pageNow > 1 && this.parameter.pageNow !== 1) {
          this.parameter.pageNow -= 1
          this.getData()
        }
      },
    },
  },
  methods: {
    handleSearch() {
      this.getData(this.inputCompanyName)
    },

    handleReset() {
      this.inputCompanyName = ''
      this.getData(this.inputCompanyName)
    },
    // 获取列表数据
    getData(InputName = '') {
      this.loading = true
      let obj = {
        pageNow: this.parameter.pageNow,
        pageSize: this.parameter.pageSize,
        name: InputName,
      }
      this.$api.outsource
        .getOutsourceData(obj)
        .then(res => {
          console.log(res)
          this.loading = false
          if (res.code !== 200) {
            this.$message.error('数据列表请求失败')
            return
          }
          this.outsourceList = res.data.records
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 根据公司名称查找数据
    searchByCompanyName() {
      this.getData(this.inputCompanyName)
    },

    // 点击按钮跳转到详情页面
    gotoDeatil(row) {
      this.form = row
      this.form.list_s = 2
      this.showEditDialog = true
    },

    // 跳转到编辑页面
    gotoEdit(row) {
      this.form = row
      this.form.list_s = 3
      this.showEditDialog = true
    },

    // 删除数据
    deleteOutsource(row) {
      const id = row.id
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.outsource
            .deleteOutsourceData(id)
            .then(res => {
              if (res.code !== 200) return this.$message.error('数据删除失败')
              this.$message.success('操作成功')
              this.getData()
            })
            .catch(err => {
              console.log(err)
              this.loading = false
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    // 监听分页栏页大小改变
    handleSizeChange(newSize) {
      this.parameter.pageSize = newSize
      this.getData()
    },

    // 点击按钮跳转到添加界面
    addOutsource() {
      this.form = {
        list_s: 1,
        status: '0',
      }
      this.showEditDialog = true
    },

    // 点击返回按钮跳转回该页面
    re() {
      this.getData()
      this.form = {}
      this.showEditDialog = false
      this.inputCompanyName = ''
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
